const projectData = [
  {
    title: "Snapshield",
    image: "./images/snapshield/post - 1.png",
    link: "/projects/Snapshield",
  },
  {
    title: "MyCreditFunding",
    image: "./images/work-8-mycreaditfundeing.png",
    link: "/projects/mycreditfunding",
  },
  {
    title: "Proforextrading",
    image: "./images/work-7-proforextrading.png",
    link: "/projects/proforextrading",
  },
  {
    title: "Yesdoc healthcare",
    image: "./images/work-6-yesdocHealtcare.png",
    link: "/projects/yesdoc healthcare",
  },
  {
    title: "Urbanfashion",
    image: "./images/shopnest.png",
    link: "/projects/urbanfashion",
  },
  {
    title: "Taskify",
    image: "./images/work-5-Taskify.png",
    link: "/projects/taskify",
  },
  {
    title: "TalkWave",
    image: "./images/work-9-talkwave.png",
    link: "/projects/TalkWave",
  },
  {
    title: "Rakesh meena's portfolio",
    image: "./images/work-4-clientPortfolio.png",
    link: "/projects/Rakesh meena's portfolio",
  },
  {
    title: "caffeine",
    image: "./images/work-1-caffine.png",
    link: "/projects/caffeine",
  },
  // {
  //   title: "MilanStar",
  //   image: "./images/work-2-milanstar.png",
  //   link: "http://localhost:3000/projects/milanStar",
  // },
  // {
  //   title: "Tom Flether",
  //   image: "./images/work-3-TomFlether.png",
  //   link: "/projects/Tom flether",
  // },
];

export const testimonials = [
  {
    text: "Dhrumit Panchal excels in frontend development, specializing in React JS, Next JS, Tailwind CSS, Bootstrap, and C/C++. His design and animation skills, quick learning, teamwork, and humility set him apart. Dhrumit has made invaluable contributions to my projects. Thank you, Dhrumit, for your exceptional collaboration!",
    date: "October 15,2023",
    name: "Yash soni",
  },
  {
    text: "Dhrumit's exceptional frontend skills, design acumen, and tech proficiency significantly enhance any project. His commitment to staying current with trends ensures visually stunning, user-friendly interfaces that align with modern design principles. He's a standout developer for UI work.",
    date: " October 24,2023",
    name: "Rakesh Meena",
  },
  {
    text: "Your outstanding work has significantly elevated our web presence. The website looks amazing and functions seamlessly, providing an excellent user experience. Your attention to detail, creativity, and problem-solving skills are invaluable. Keep up the fantastic work; you are a crucial and greatly appreciated part of our team!",
    date: " jun 07,2024",
    name: "Jainam Jain",
  },
  {
    text: "Extremely satisfied with the work , I've got what Ive expected developer was very helpful & supportive whenever I need to add or remove something from website they get it done on prior basis ,over all work was good",
    date: " jun 07,2024",
    name: "Khushal Dalwani & Ashwin ashwani",
  },
];

export default projectData;
